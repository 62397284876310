import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/UpdateLayout.js";
import ListItem from 'components/LinkCard/ListItem';
import List from '../../../components/LinkCard/List';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Our second official release includes a whole bunch of new components.`}</p>
    <h2 {...{
      "id": "form-elements",
      "style": {
        "position": "relative"
      }
    }}>{`Form elements`}</h2>
    <p>{`This release focused on tackling the more obvious form elements. A set of
standard form elements were still missing, but this release aims to solve most
needs when it comes to forms.`}</p>
    <p>{`Select fields, switches, radio buttons and fieldsets are included in this
release.`}</p>
    <List mb={8} items={[{
      title: 'Switch',
      description: '',
      to: `/components/switch/code`
    }, {
      title: 'Select',
      description: '',
      to: `/components/select/code`
    }, {
      title: 'Radio',
      description: '',
      to: `/components/radio/code`
    }, {
      title: 'Fieldset',
      description: '',
      to: `/components/fieldset/code`
    }]} mdxType="List" />
    <h2 {...{
      "id": "utility",
      "style": {
        "position": "relative"
      }
    }}>{`Utility`}</h2>
    <p>{`Next to our focus on forms, we also now provide components that are more
utility-based. Screenreader components render their children, but still exposes
them to screenreaders.`}</p>
    <p>{`Responsiveness can now also added to patterns that use our components. While
some technical restrictions that are outside of our control limit us to provide
full baked-in responsiveness, we now provide a custom React hook. You can use
this hook to generate a value that will change based on screen width. You can
then pass this value as a prop to a component, making it auto adapt when
changing dimensions.`}</p>
    <p>{`Lastly, and continuing on the responive bandwagon, we also provide a Hidden
component that will visually hide its children between or outside of certain
breakpoints.`}</p>
    <List mb={8} items={[{
      title: 'Screenreader',
      description: '',
      to: `/components/screenreader/code`
    }, {
      title: 'useMediaQuery',
      description: '',
      to: `/components/use-media-query/code`
    }, {
      title: 'Hidden',
      description: '',
      to: `/components/hidden/code`
    }]} mdxType="List" />
    <h2 {...{
      "id": "cloning-a-box",
      "style": {
        "position": "relative"
      }
    }}>{`Cloning a box`}</h2>
    <p>{`Box components now accept a clone prop. This clone prop will copy the resulting
styling from its passed-in props right onto its child. However, do note that not
all props that the Box component accepts, will all be passed through to its
children. We don't want to allow full freedom, e.g. we don't want a button's
padding to be adaptable by adding a box around it with a clone prop. To have a
clear overview of all cloneable props, you can check every component's page.
There's an overview of the cloneable props there, right under the props table.`}</p>
    <h2 {...{
      "id": "expanding-design-guidelines",
      "style": {
        "position": "relative"
      }
    }}>{`Expanding design guidelines`}</h2>
    <p>{`Our first release had a single component that had a dedicated page to illustrate
how it should be used in design. This releases adds design guidelines to a few
more components. This is an ongoing process, so expect the next few releases to
contain design guidelines additions.`}</p>
    <List my={8} mdxType="List">
  <ListItem title="v0.5.0 changelog" description="Get a full overview of what's changed in the changelog" to="/updates/changelog" mdxType="ListItem" />
    </List>
    <h2 {...{
      "id": "looking-forward",
      "style": {
        "position": "relative"
      }
    }}>{`Looking forward`}</h2>
    <p>{`Every release, we'll try to give you an insight on our plans for the next
release. That way you know what to expect, and see how you can adapt to our new
features.`}</p>
    <p>{`Next release will include more branding options. Currently, most components
don't differ that much when it comes to branding, but that's because they are
low-level building blocks. With the Loader and BrandedHeader component, brands
will have bigger freedom to differentiate. Our efforts in the form department
will also continue. Next release,`}</p>
    <p>{`we'll have a Datepicker and the possibility to provide masks to your input
fields.`}</p>
    <p>{`Another aim of the next release will be Modals, Dialogs, Popovers, Tooltips. The
exact implementation is to be seen.`}</p>
    <p>{`Lastly we also want to provide you with an even clearer overview of when to
expect what component. We'll be doing this by adding an overview table to our
components page that will, in rough lines, highlight our current roadmap.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      